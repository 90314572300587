.left-widget {
  border-width: 1px;
  border-style: solid;
  border-color: #eee;
  background-color: #fff;
  border-radius: 6px;
  min-height: 949px;
}

.small-left-widget {
  border-width: 1px;
  border-style: solid;
  border-color: #eee;
  background-color: #fff;
  border-radius: 6px;
  min-height: 430px;
}

.widget {
  background-color: #fbfbfb;
  border: solid 1px #eee;
  border-radius: 4px;
  padding: 1rem;
}

.fit {
  width: 100%;
  position: relative;
}

.cover-photo-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.cover-photo {
  width: 100%;
  display: block;
  height: 100%;
}

.avatar {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Arial";
  color: #fff;
  font-weight: 600;
}

.avatar-icon {
  background-color: #309937;
  font-family: "Arial";
  color: #fff;
  font-weight: 600;
}

.avatar-letter {
  background-color: #309937;
  font-family: "Arial";
  color: #fff;
  font-size: 48px !important;
  font-weight: 600;
}

@media (max-width: 768px) {
  .left-widget {
    min-height: auto;
  }
}
