.blue-bg {
  background-color: #21575b;
  text-align: center;
  color: #fff;
  padding: 1rem;
}

.wwd-banner {
  background-image: url("../../Assets/images/WhatWeDo/banner.png");
  color: #21575b;
  text-align: center;
  font-family: "Aleo";
  font-style: italic;
  padding: 1rem;
}

.home-banner-container {
  background-color: #173b3e;
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  min-height: 550px;
}

.home-banner-image {
  width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
}

.home-banner-head {
  text-align: center;
  font-weight: 600;
  font-size: 72px;
  font-family: "Aleo";
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-banner-text {
  position: absolute;
  top: 6%;
  color: white;
  font-family: "Aleo";
  font-weight: 600;
  width: 100%;
}

.home-caption {
  font-family: Arial;
  text-align: center;
  font-size: 20px;
}

.home-center-p-btn {
  bottom: -40%;
  position: absolute;
  left: 25%;
  transform: translate(-25%, 50%);
  width: 100%;
}

.home-banner-desc-layout {
  position: absolute;
  bottom: 18%;
  left: 28%;
  transform: translateX(-20%);
}

.home-banner-p {
  font-size: 20px;
  color: white;
  font-family: "Aleo", sans-serif;
  font-weight: 400;
  text-align: center;
  font-style: italic;
  line-height: 1.8;
}

.normal {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
}

.normal-text {
  font-style: normal;
  font-weight: 600;
}

.client-logos {
  width: 140px;
}

.ant-layout-header {
  height: auto;
  line-height: 0px;
  background: #141414;
}

.white {
  color: #fff !important;
}

.paragraph {
  line-height: 1.5;
  font-family: Arial;
  text-align: justify;
  text-justify: inter-word;
}

.left-aligned-pgp {
  line-height: 1.5;
  font-family: Arial;
  text-justify: inter-word;
}

.testimonial {
  line-height: 1.75;
  font-family: Arial;
  text-align: justify;
  text-justify: inter-word;
  font-style: italic;
}

.testimonial-watermark {
  background-image: url("../../Assets/images/Home/quotes.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: bottom;
}

.head {
  font-size: 48px !important;
  letter-spacing: 1px !important;
}

.head26 {
  font-size: 26px !important;
  font-weight: 600;
}

.patient-name {
  color: #006747;
  font-family: Arial;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs30 {
  font-size: 30px !important;
}

.fs32 {
  font-size: 32px !important;
}

.fs42 {
  font-size: 42px !important;
}

.fs52 {
  font-size: 52px !important;
}

.white-watermark {
  background-image: url("../../Assets/images/Home/wwdBg.png");
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}

.yellow-widget {
  background-color: #ffc100;
  padding: 2rem 1rem;
  border-radius: 8px;
  box-shadow: 0 0 0 2px rgba(143, 145, 146, 0.1);
  min-height: 320px;
}

.blue-widget {
  background-color: #1fa7b2;
  padding: 2rem 1rem;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px rgba(143, 145, 146, 0.1);
  min-height: 320px;
}

.orange-widget {
  background-color: #f26511;
  padding: 2rem 1rem;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 0 2px rgba(143, 145, 146, 0.1);
  min-height: 320px;
}

.black-text-shadow {
  text-shadow: 0px 2px 2px #aaa;
}

.white-text-shadow {
  text-shadow: 0px 2px 2px #4b4b4b;
}

@media (max-width: 768px) {
  .home-banner-container,
  .home-banner-image {
    min-height: 562px;
  }
  .normal {
    font-size: 18px;
  }
  .client-logos {
    width: 120px;
  }
  .home-banner-text {
    top: 6%;
  }
  .home-caption {
    font-size: 18px;
  }
  .home-banner-p {
    font-size: 16px;
    line-height: 26px;
    padding: 0 1rem;
  }
  .home-banner-head {
    font-size: 40px;
  }
  .home-banner-desc-layout {
    position: absolute;
    bottom: 10%;
    left: unset;
    transform: unset;
  }
  .paragraph {
    text-align: unset;
  }
}

@media (min-width: 769px) and (max-width: 1177px) {
  .home-banner-container,
  .home-banner-image {
    min-height: 500px;
  }
  .home-banner-head {
    font-size: 54px;
  }
  .home-banner-text {
    top: 8%;
  }
  .home-banner-desc-layout {
    position: absolute;
    bottom: 10%;
    left: unset;
    transform: unset;
  }
  .home-banner-p {
    font-size: 18px;
    line-height: 22px;
  }
  .yellow-widget,
  .blue-widget,
  .orange-widget {
    min-height: 330px;
  }
}
