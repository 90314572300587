.blue-banner {
  background: linear-gradient(180deg, #16929c 0%, #056b73 100%);
  background-color: #16929c;
  color: #fff !important;
  padding: 4rem 2rem;
}

.blue-banner-1 {
  background-image: url("../../Assets/images/CognitiveJourney/bg1.png");
  background-color: #21575b;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff !important;
  padding: 4rem 2rem;
}

.blue-banner-2 {
  background-image: url("../../Assets/images/CognitiveJourney/bg2.png");
  background-color: #21575b;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff !important;
  padding: 4rem 1rem;
}

.respiratory-bg {
  background-image: url("../../Assets/images/Respiratory/bg.png");
  background-color: #21575b;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff !important;
  padding: 4rem 1rem;
}

.fs28 {
  font-size: 28px !important;
}

.paragraph108 {
  line-height: 1.8;
  font-family: Arial;
  text-align: justify;
  text-justify: inter-word;
}

.ln-hgt {
  line-height: 1.8;
}

.ln-hgt105 {
  line-height: 1.5;
}

.sw-blue {
  color: #1d54b5;
}

@media (max-width: 768px) {
  .blue-banner,
  .blue-banner-1,
  .blue-banner-2,
  .respiratory-bg {
    padding: 1rem;
  }

  .fs28 {
    font-size: 24px !important;
  }
}
