.banner-container {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.banner-image {
  width: 100%;
  display: block;
  object-fit: cover;
  height: 100%;
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5vw;
  color: white;
  font-family: "Aleo", sans-serif;
  font-weight: 700;
  text-align: center;
}

.login-logo {
  width: 70%;
}

.login-logo-text {
  font-size: 42px !important;
}

.fixed-header {
  background-color: var(--background-color);
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.2));
  padding: 0.75rem 1.25rem;
  position: sticky;
  top: 0;
  z-index: 1;
}

.white-footer {
  background: var(--background-color);
  text-align: center;
  color: var(--text-color);
  padding: 1rem 0;
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.6));
}

.dark-footer {
  background: var(--footer-bg);
  color: #fff;
  padding: 15px 0 10px 0;
}

.shadow {
  filter: "drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))";
}

.auth-layout {
  background-color: var(--layout-bg-color);
  padding: 1rem 1.25rem;
}

.m0 {
  margin: 0 !important;
}

.menu {
  display: flex;
  background-color: #21575b;
  color: #fff;
  line-height: 60px;
  padding: 0rem 1.5rem;
}

@media (max-width: 768px) {
  .banner-text {
    font-size: 30px;
  }
  .banner-image {
    min-height: 200px;
  }

  .login-logo {
    width: 40%;
    margin: 1rem;
  }
  .login-logo-text {
    font-size: 28px !important;
  }
  .auth-layout {
    padding: 2rem 1rem;
  }
  .custom-header {
    padding: 0.65rem 1rem;
  }
  .menu {
    padding: 0 1rem;
  }
  .dark-footer {
    text-align: center;
  }
  .top-margin {
    margin-top: 0.5rem;
  }
}
