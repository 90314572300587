:root {
  --background-color: #ffffff;
  --layout-bg-color: #e3e3e3;
  --text-color: #000000;
  --footer-bg: #21575b;
  --blue-text: #21575b;
  --black-text: #141414;
  --sider-bg: #21575b;
  --sider-inner-bg: #1c494c;
  --sider-border: #21575b;
  --table-header: #01c6b2;
  --box-shadow: #777;
  --grey-text: #6e6b6b;
  --grey-fill: #f8f8f8;
  --card-bg: #fff;
  --orange: #f26511;
  --blue-bg: #1cb4bf;
}

[data-theme="dark"] {
  --background-color: #141414;
  --layout-bg-color: #3c3c3c;
  --text-color: #ffffff;
  --blue-text: #fff;
  --black-text: #fff;
  --footer-bg: #141414;
  --sider-bg: #1f1f1f;
  --sider-inner-bg: #141414;
  --sider-border: #282828;
  --table-header: #282828;
  --box-shadow: #141414;
  --grey-text: #bcbaba;
  --grey-fill: #282828;
  --card-bg: #282828;
  --orange: #993e08;
  --blue-bg: #28696d;
}

@font-face {
  font-family: "Aleo";
  src: local("Aleo-Regular"), local("Aleo-Regular"),
    url("../src/Assets/Aleo.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AleoLight";
  src: local("Aleo-Regular"), local("Aleo-Regular"),
    url("../src/Assets/AleoLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px !important;
}

.icon18 {
  font-size: 18px;
}

.icon24 {
  font-size: 24px;
}

.selected-tag {
  background-color: #2b7378;
  color: white;
  border: #266569 solid 1px;
}

.orange-card-header {
  background-color: #f26511;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 8px 8px 0 0;
  font-weight: 600;
  font-size: 20px;
}

.menu-icon {
  font-size: 16px !important;
  margin-right: 10px;
}

.submenu-icon {
  font-size: 16px !important;
}

.mt025 {
  margin-top: 0.25rem;
}

.mt05 {
  margin-top: 0.5rem;
}

.mt075 {
  margin-top: 0.75rem;
}

.mt1 {
  margin-top: 1rem;
}

.mt1025 {
  margin-top: 1.25rem;
}

.mt105 {
  margin-top: 1.5rem;
}

.mt2 {
  margin-top: 2rem;
}

.mt3 {
  margin-top: 3rem;
}

.mt4 {
  margin-top: 4rem;
}

.mt5 {
  margin-top: 5rem;
}

.mt6 {
  margin-top: 6rem;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb05 {
  margin-bottom: 0.5rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.mb105 {
  margin-bottom: 1.5rem;
}

.mb2 {
  margin-bottom: 2rem;
}

.pd4 {
  padding: 4rem;
}

.w90 {
  width: 90%;
}

.display-inline {
  display: inline-block;
}

.flex-baseline {
  display: flex;
  justify-content: left;
  align-items: baseline;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: end;
  justify-content: end;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-start-center {
  display: flex;
  justify-content: left;
  align-items: center;
}

.align-center {
  align-items: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex {
  display: flex;
}

.slider-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-item-required {
  color: red;
}

.custom-table .ant-table-tbody > tr.highlight-row {
  background-color: #ffc38a !important;
}

.custom-table .ant-table-tbody > tr.highlight-row:hover td {
  background-color: #ffd7b1 !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.footer-text-center {
  text-align: unset;
}

.mt1l1 {
  margin: 0.5rem 1rem 0 0;
}

.signUpWidget {
  background-color: #fff;
  padding: 3rem;
  text-align: center;
  margin: 0 auto;
}

.orange-filler {
  background-color: #ff7d01;
  border-radius: 4px 0 0 4px;
}

.blue-filler {
  background-color: #1cb4bf;
  border-radius: 4px 0 0 4px;
}

.green-filler {
  background-color: #00a73e;
  border-radius: 4px 0 0 4px;
}

.unAuthWidget {
  background-color: #fff;
  border: solid 1px #eee;
  border-radius: 4px;
}

.full-width {
  width: 100% !important;
}

a {
  color: #ff7d01;
}

.high-alert {
  color: red;
  border: solid 1px red;
  border-radius: 3px;
  text-align: center;
}

.low-alert {
  color: #1cb4bf;
  border: solid 1px #1cb4bf;
  border-radius: 3px;
  text-align: center;
}

.orange {
  color: #ff7d01 !important;
}

.light-blue {
  color: #1cb4bf !important;
}

.yellow {
  color: #ffcd00 !important;
}

.blue {
  color: var(--blue-text) !important;
}

.green {
  color: #00a73e !important;
}

.light-green {
  color: #34b19e !important;
}

a:hover {
  color: #d6550b;
}

.whiteText {
  color: #fff !important;
}

.mh100vh {
  min-height: 100vh;
}

.mh80vh {
  min-height: 80vh;
}

.grey {
  color: var(--grey-text);
}
.black {
  color: var(--black-text);
}

.card {
  background-color: #fbfbfb;
  border: solid 1px #bbb;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.center-bottom {
  bottom: 8%;
  position: absolute;
  right: 0;
  left: 0;
}

.left-bottom {
  bottom: 4%;
  position: absolute;
  left: 6%;
}

.white-link {
  color: #fff;
  text-decoration: underline;
}

.white-link:hover {
  color: #fff;
  text-decoration: underline;
}

.black-link {
  color: rgba(0, 0, 0, 0.88);
  text-decoration: underline;
}

.black-link:hover {
  color: rgba(0, 0, 0, 0.6);
  text-decoration: underline;
}

.fw400 {
  font-weight: 400 !important;
}

.fw700 {
  font-weight: 700 !important;
}

.left {
  float: left;
}

.right {
  float: right;
}

.ml1 {
  margin-left: 1rem;
}

.ml3 {
  margin-left: 3rem;
}

.mr025 {
  margin-right: 0.25rem;
}

.mr05 {
  margin-right: 0.5rem !important;
}

.ml025 {
  margin-left: 0.25rem !important;
}

.ml05 {
  margin-left: 0.5rem !important;
}

.mr075 {
  margin-right: 0.75rem !important;
}

.mr1 {
  margin-right: 1rem !important;
}

.media-mt-3 {
  margin-top: 3rem;
}

.section-bg {
  padding: 0.75rem 3rem;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 1rem 0 0 0;
}

.videos {
  padding: 2rem 3rem;
}

textarea {
  min-height: 47px;
}

.w32 {
  width: 32px;
}

.pd025 {
  padding: 0.25rem;
}

.pd05 {
  padding: 0.5rem;
}

.pd075 {
  padding: 0.75rem;
}

.pdtb05 {
  padding: 0.5rem 0;
}

.pdtb1 {
  padding: 1rem 0;
}

.pdlr1 {
  padding: 0.75rem 1rem;
}

.pd-tb0-lr1 {
  padding: 0 1rem;
}

.pd-tlr1-b0 {
  padding: 1rem 1rem 0 1rem;
}

.pd1 {
  padding: 1rem;
}

.pd2 {
  padding: 2rem;
}

.pd3 {
  padding: 3rem;
}

.bold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.ul {
  padding-inline-start: 40px;
}

.spinner {
  font-size: 34px;
}

.ovf-auto {
  overflow: unset;
}
.light-shadow {
  box-shadow: 0px 0px 10px 0px #e0e0e0;
}

.box-shadow {
  box-shadow: 0px 3px 6px 1px var(--box-shadow);
}

.more-shadow {
  box-shadow: 6px 10px 12px 2px #ccc;
}

.box-more-shadow {
  box-shadow: 10px 16px 20px 0px #b2b2b2;
}

.bdr3 {
  border-radius: 3px;
}

.bdr8 {
  border-radius: 8px !important;
}

.underline {
  text-decoration: underline !important;
}

.privacy-policy {
  padding: 3rem;
  line-height: 1.5rem;
  font-size: 14px;
}

.ant-timeline-item-content ul {
  padding-inline-start: 20px;
}

.timeline-label {
  font-size: 20px;
  font-weight: 600;
  color: #21575b;
}

.timeline-sub-text {
  font-size: 12px;
  font-weight: 600;
  color: #787575;
}

.header-margin-01 {
  margin: 0rem 0 1rem 0 !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 1rem;
}
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.video-container {
  display: flex;
  justify-content: center;
}

.video-player {
  width: 680px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.dashboard-banner-container {
  background-color: #1fa7b2;
  height: 130px;
  position: relative;
  display: block;
  padding: 1.25rem;
}

.dashboard-banner-head {
  font-weight: 600;
  font-size: 22px;
}

.dashboard-banner-text {
  margin-top: 1rem;
  line-height: 1.5;
}

.dashboard-banner-image {
  position: absolute;
  transform: translate(0%, -39%);
  right: 0;
  width: 176px;
}

.wd50 {
  width: 50% !important;
}

textarea {
  min-height: 47px;
}

.btn-extra-pd {
  padding: 9px !important;
  font-weight: bold;
}

.blue-rgb {
  background: linear-gradient(
    90deg,
    rgb(0, 0, 0, 0.2) 0%,
    rgb(0, 0, 0, 0) 50%,
    rgb(0, 0, 0, 0.2) 100%
  );
  background-color: rgb(0, 0, 0, 0.2);
  padding: 1rem 1rem 0 1rem;
}

.orange-rgb {
  background: linear-gradient(
    90deg,
    rgb(190, 77, 10, 0.9) 0%,
    rgb(227, 93, 12, 0.9) 50%,
    rgba(190, 77, 10, 0.9) 100%
  );
  background-color: rgb(0, 0, 0, 0.2);
  padding: 1rem 1rem 0 1rem;
}

.day-widget {
  padding: 1rem;
  border: solid 2px #28696d;
  background-color: #fff;
  color: #28696d;
  text-align: center;
  border-radius: 8rem;
  margin: 0 1rem;
  width: 60%;
}

.orange-plane-card {
  border: solid 1px var(--orange);
  border-radius: 8px;
  background-color: var(--card-bg);
}

.vertical-align {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

p {
  line-height: 1.5;
}

.top {
  margin: 0;
}

.bottom {
  margin: 0;
}

.list-paragraph {
  list-style-type: none;
  padding-left: 0;
}

.list-paragraph li {
  margin-bottom: 0.75rem;
}

.list-paragraph {
  list-style-type: none;
  padding-left: 0;
}

.list-paragraph li {
  margin-bottom: 0.75rem;
}

.selected-card {
  background-color: #ff7d01;
  color: #fff;
}

.hover-text {
  color: #373737;
}

.hover-text:hover {
  color: #ff7d01;
  background-color: #ffebd9;
}

@media (max-width: 768px) {
  .signUpWidget {
    padding: 1.5rem;
  }
  .ovf-auto {
    overflow: scroll;
  }
  .pd3,
  .pd2,
  .pd4 {
    padding: 1rem;
  }
  .video-player {
    width: 100%;
    height: 100%;
  }
  .section-bg {
    font-size: 16px;
    padding: 0.75rem 1rem;
  }
  .videos {
    padding: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 1177px) {
  .section-bg {
    font-size: 14px;
    padding: 0.75rem 2rem;
  }
  .videos {
    padding: 1rem 2rem;
  }
}

/* Extra small devices (xs: <576px) */
@media (max-width: 575.98px) {
  .dashboard-banner-container {
    height: 100px;
    padding: 0.75rem;
  }
  .dashboard-banner-head {
    font-size: 16px;
  }
  .dashboard-banner-text {
    font-size: 12px;
    margin-top: 0.5rem;
  }
  .dashboard-banner-image {
    transform: translate(20%, -15%);
    width: 100px;
  }
}

/* Small devices (sm: ≥576px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .dashboard-banner-container {
    height: 128px;
  }
  .dashboard-banner-image {
    transform: translate(16%, -28%);
    width: 145px;
  }
}

/* Medium devices (md: ≥768px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .dashboard-banner-container {
    height: 120px;
  }
  .dashboard-banner-image {
    transform: translate(16%, -31%);
    width: 140px;
  }
}

/* Large devices (lg: ≥992px) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (xl: ≥1200px) */
@media (min-width: 1200px) and (max-width: 1599.98px) {
}

/* Extra extra large devices (xxl: ≥1600px) */
@media (min-width: 1600px) {
}

@media screen and (max-width: 991px) {
  .mt6 {
    margin-top: 2rem;
  }
  .footer-text-center {
    text-align: center;
  }
  .media-mt-3 {
    margin-top: 2rem;
  }
}

@media (min-width: 580px) and (max-width: 991px) {
  .media-mt-3 {
    margin-top: 1rem;
  }
}
