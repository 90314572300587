.orange-card {
  background-color: #f26511;
  color: #fff;
  border-radius: 8px !important;
}

.green-card {
  background-color: #34b19e;
}

.yellow-card {
  background-color: #ffc100;
}

.blue-card {
  color: #fff;
  border-radius: 10px !important;
  border: none;
  background-color: var(--blue-bg);
}

.info-card {
  background-color: #ceffe0;
  color: #00802f;
  padding: 5px 10px;
  border-radius: 4px !important;
}

.orange-card > .ant-card-body,
.green-card > .ant-card-body,
.yellow-card > .ant-card-body,
.blue-card > .ant-card-body {
  padding: 20px 15px !important;
}

.p0-blue-card > .ant-card-body {
  background-color: #1fa7b2;
  color: #fff;
  padding: 0px 15px !important;
}
